import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";
// import { FiArrowRightCircle } from "react-icons/fi";
// import Image from "@components/common/CloudinaryImage";
// import { Link } from "gatsby";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <>
            <ProductTemplate item={item}>
                {/** ¿Qué es? */}
                <div className="container lg:px-0 py-20">
                    <Title>
                        Les 500 preguntes imprescindibles per accedir al cos de Mossos d'Esquadra
                        (7a edició - 23/24)
                    </Title>

                    {/*<div className="mt-5 p-5 bg-orange-100 text-sm rounded-md overflow-hidden shadow-md font-montserrat text-orange-500 space-y-5">
                        <p>
                            Ya disponible, y en primicia, nuestro nuevo Libro:{" "}
                            <strong>
                                Las 500 preguntas imprescindibles para acceder
                                al Cuerpo de Mossos d'Esquadra.
                            </strong>{" "}
                            500 preguntas que te harán afrontar la primera
                            prueba con garantías y empezar bien el proceso.
                        </p>
                        <p className="font-semibold">
                            Reserva el libro ahora por 34,99€ + 4,49€ (envío
                            certificado).
                        </p>
                    </div>*/}

                    <Text className="mt-10 space-y-5">
                        <p>
                            Acabada de sortir d'impremta la 7a edició del primer volum de
                            <em>
                                Les 500 preguntes imprescindibles per accedir al Cos de Mossos
                                d'Esquadra
                            </em>
                            .
                            <br />
                            <br />
                            Les 500 preguntes que has de saber sí o sí. Seran la base de tot el teu
                            estudi i afrontaràs la primera prova amb garanties d'iniciar bé el
                            procés.
                        </p>
                        <p className="font-semibold">
                            Compra el llibre ara i rep-lo a partir del 10 d'octubre.
                        </p>
                    </Text>

                    <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                        <iframe
                            title={item.title}
                            width="100%"
                            height="600px"
                            src="https://www.youtube.com/embed/ZefgSQzwqkQ"
                            frameBorder={"0"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen={true}
                        ></iframe>
                    </div>
                </div>
            </ProductTemplate>
        </>
    );
};
export default Product;
